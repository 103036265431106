














import { Vue, Component, Prop } from "vue-property-decorator";

export interface TileComponent {
  id: number;
  title?: string;
  image?: string;
  path?: string;
}

@Component
export default class MultiTile extends Vue {
  @Prop({ default: "" }) title?: string;
  @Prop({ default: [] }) tiles?: TileComponent[];
  @Prop({ default: "white" }) color?: string;

  get displayTitle(): string | undefined {
    return (this.title ?? "").length > 0 ? this.title : undefined;
  }
}
